<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder">
                    <label>Employee Phone Number </label>
                    <the-mask v-if="this.userPhone" type="text" @focus="ResetDirty('EmployeePhoneNumber')"
                        @blur="SetDirty('EmployeePhoneNumber')" :tokens="{ '#': { pattern: /\d/ } }"
                        :mask="['##', '1-###-###-####']" style="margin-top:1px;" class="form-control" autocomplete="off"
                        :maxlength="14" v-model="MediaVendor.EmployeePhoneNumber" placeholder="Employee Phone Number"
                        disabled></the-mask>

                    <b-form-input v-else placeholder="N/A" disabled></b-form-input>

                    <div class="error-message-format"
                        v-if="$v.MediaVendor.EmployeePhoneNumber.$dirty && !$v.MediaVendor.EmployeePhoneNumber.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder mt-2">
                    <label>AGENCY </label>
                    <OmniSelect placeholder="Select Agency" name="Media" :showLabel="false" label="Select Media"
                        :options="AgencyList" v-model="MediaVendor.EmployeeAgency" />
                </div>

            </div>
            <!-- <div class="d-block row col-lg-8 offset-lg-2 mt-3 agree-checkbox">
            <b-form-checkbox value="true" v-model="MediaVendor.Attestation">By checking this box, the requestor is confirming that he/she/they has conducted all acceptable measures of due diligence to ensure the propriety of the vendor and confirm that the information contained on the form is accuraate to the best of their knowledge</b-form-checkbox>
        </div> -->
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                BACK
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
                NEXT
            </button>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TheMask } from 'vue-the-mask'
import service from "../../../services/Dropdowns";
import { required, minLength, requiredIf, maxLength, numeric } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            userPhone: null,
            AgencyList: [],
        }
    },
    components: {
        TheMask
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    validations: {
        MediaVendor: {
            EmployeePhoneNumber: {
                required: requiredIf(function () {
                    return this.userPhone;
                }),
                minLength: minLength(10),
                maxLength: maxLength(10),
                numeric
            },
            EmployeeAgency: {
                required
            },
        }
    },
    computed: {
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('mediavendor', {
            MediaVendor: 'MediaVendor',
        }),
    },
    mounted() {
        this.getPhoneNumber();
        this.LoadAgency();
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    methods: {
        SetDirty(propertyName) {
            this.$v.MediaVendor[propertyName].$touch();

        },
        ResetDirty(propertyName) {
            this.$v.MediaVendor[propertyName].$reset();
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Review",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Review"
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Relationship Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Relationship Info"
                });
            }
        },
        async getPhoneNumber() {
            try {
                const emailAddress = await this.$store.dispatch("getEmail");
                const res = await this.$store.dispatch("product/GetReachUser", emailAddress);
                if (res.data) {
                    this.MediaVendor.EmployeePhoneNumber = res.data.Phone;
                    this.userPhone = this.MediaVendor.EmployeePhoneNumber.length;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        async LoadAgency() {
            service
                .getAgencyDropdownValues("")
                .then((res) => {
                    this.AgencyList = res.data;
                    this.UpdateAgencyName(this.MediaVendor.EmployeeAgency)
                })
                .catch((err) => {
                    alert(err);
                });
        },
        UpdateAgencyName(newVal) {
            const agencyValue = this.AgencyList.findIndex((item) => {
                return item.value == newVal;
            });
            if (agencyValue > -1) {
                this.MediaVendor.AgencyName = this.AgencyList[agencyValue].label;
            } else {
                this.MediaVendor.AgencyName = "";
            }
        }
    },
    watch: {
        "MediaVendor.EmployeeAgency": function (val) {
            this.UpdateAgencyName(val);
        },
    },
}
</script>

<style scoped>
.agree-checkbox .custom-control-label {
    font-weight: 400;
    font-size: 12px;
}
</style>
